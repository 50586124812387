import { Link, useNavigate } from "@remix-run/react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { RiArrowLeftLine, RiArrowRightSLine } from "react-icons/ri";
import type { Advertising } from "~types/api/advertising.types";

import { AdvertisingCard } from "~/components/AdvertisingCard";
import { Button } from "~/components/ui/button";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { Heading, Text } from "~/components/ui/typography";
import { cn } from "~/utils/classnames";

interface BreadcrumbLink {
  name: string;
  slug?: string;
  currentPage?: boolean;
}

interface PageHeadingProps extends ComponentPropsWithoutRef<typeof Wrapper> {
  breadcrumbData?: BreadcrumbLink[] | null | undefined;
  headingText?: string;
  headingTextSecondary?: string;
  HeadingIconComponent?: ReactNode;
  description?: string;
  advertising?: Advertising | null;
  withBackButton?: boolean;
}

export const PageHeading = ({
  breadcrumbData,
  headingText,
  headingTextSecondary,
  description,
  HeadingIconComponent,
  advertising,
  withBackButton = false,
  className,
  ...props
}: PageHeadingProps) => {
  const navigate = useNavigate();

  // const HeadingIcon = HeadingIconComponent
  //   ? cloneElement(HeadingIconComponent, {
  //       className: cn(
  //         "h-6 w-6 text-grey-800 sm:h-7 sm:w-7",
  //         HeadingIconComponent.props.className
  //       ),
  //     })
  //   : null;

  return (
    <Wrapper
      className={cn(
        "-mt-4 mb-4 flex flex-col-reverse justify-between gap-4 pt-2 sm:-mt-5 sm:mb-5 md:-mt-6 md:mb-6 md:flex-row md:items-center md:justify-start md:pt-3 lg:-mt-7 lg:mb-7 lg:pt-4 xl:-mt-8 xl:mb-8",
        className
      )}
      {...props}
    >
      <div className="grow basis-1/2" aria-label="Navigation dans le fil d'ariane">
        {breadcrumbData ? (
          <nav>
            <ol
              className="flex flex-wrap items-center gap-0 text-xs font-medium tracking-wide text-grey-500"
              itemScope
              itemType="https://schema.org/BreadcrumbList"
            >
              <Link to="/" aria-label="Retour à l'accueil" className="block p-1 hover:underline">
                Accueil
              </Link>
              {breadcrumbData.length > 0 ? (
                <RiArrowRightSLine className="h-4 w-4 shrink-0" />
              ) : null}
              {breadcrumbData.map((item, index) => (
                <div key={`${item.name}-${item.slug}`} className="flex min-w-1 items-center">
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                    className="min-w-1 shrink"
                  >
                    {item.currentPage || index === breadcrumbData?.length - 1 ? (
                      <>
                        <span
                          itemProp="name"
                          className="block cursor-default truncate p-1 text-grey-900"
                        >
                          {item.name}
                        </span>
                        <meta itemProp="position" content={String(index + 1)} />
                      </>
                    ) : (
                      <>
                        <Link
                          itemProp="item"
                          to={item.slug ?? ""}
                          className="block truncate p-1 hover:underline"
                        >
                          <span itemProp="name" className="">
                            {item.name}
                          </span>
                        </Link>
                        <meta itemProp="position" content={String(index + 1)} />
                      </>
                    )}
                  </li>
                  {index < breadcrumbData.length - 1 ? (
                    <RiArrowRightSLine className="h-4 w-4 shrink-0" />
                  ) : null}
                </div>
              ))}
            </ol>
          </nav>
        ) : null}

        {headingText || description ? (
          <div className="mt-4">
            {headingText ? (
              <Heading as="h1" className="mt-4">
                {headingText}
                {headingTextSecondary ? (
                  <span className="text-grey-500"> | {headingTextSecondary}</span>
                ) : null}
              </Heading>
            ) : null}

            {description ? (
              <Text muted className="mt-2">
                {description}
              </Text>
            ) : null}
          </div>
        ) : null}

        {withBackButton ? (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => navigate(-1)}
            icon={<RiArrowLeftLine />}
            className="mt-4"
          >
            Retour
          </Button>
        ) : null}
      </div>

      {advertising ? <AdvertisingCard advertising={advertising} banner /> : null}
    </Wrapper>
  );
};

import type { Advertising } from "~types/api/advertising.types";

import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import { Image } from "~/components/ui/image";
import { analytics } from "~/utils/analytics";
import { cn } from "~/utils/classnames";

export const AdvertisingCard = ({
  advertising,
  banner = false,
  className,
}: {
  advertising: Advertising;
  banner?: boolean;
  className?: string;
}) => {
  return (
    <Card
      className={cn(
        "relative flex justify-end overflow-hidden",
        !banner && "min-h-[500px] flex-col",
        banner && "aspect-[16/4] w-[800px] max-w-full basis-1/2 flex-row items-end",
        className
      )}
    >
      <img
        className="absolute inset-0 h-full w-full object-cover"
        src={advertising.coverImage}
        alt="Publicité"
      />

      {!banner && advertising.brandImage ? (
        <div className="absolute left-0 top-6 flex min-w-20 items-center justify-center overflow-hidden rounded-r-lg border border-l-0 border-grey-100 bg-white px-3 py-0 text-xs font-medium text-grey-900">
          <Image
            src={advertising.brandImage}
            alt="Marque de la publicité"
            className="h-14 object-contain"
          />
        </div>
      ) : null}

      <Button
        as="link"
        to={advertising.ctaLink}
        target={advertising.ctaTarget}
        rel={advertising.ctaTarget === "_blank" ? "noopener noreferrer" : undefined}
        onClick={() => {
          analytics.event({
            action: "click",
            data: {
              event_category: banner ? "pub-banniere" : "pub-vignette",
              event_label: advertising.title,
            },
          });
        }}
        className="relative z-10"
      >
        {advertising.ctaTitle}
      </Button>
    </Card>
  );
};
